/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { BASE_URL_MANAGER } from "@/config";

import Axios from "axios";
import { Component } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import AdminNavbar from "@/app/components/admin-navbar/AdminNavbar.vue";
import AgentCreateForm from '@/app/components/agents-management/dedicated-components/agent-create-form/AgentCreateForm.vue'
import Vue from 'vue';
import $ from 'jquery';

@Component({
    name: "companies-management",
    components: { AdminNavbar, AgentCreateForm }
})
export default class CompaniesManagement extends VueWizard {


    /**
     * Bus para los eventos entre componentes
     */
    public EventBus: Vue = new Vue();
    /**
     * objeto para el formulario
     */
    public company = {
        _id: null,
        name: ''

    }

    /**
     * objeto de validacion de formulario
     */
    public fromValid = {
        _id: null,
        name: ''

    }

    /**
     * lista de compañias
     */
    public companies = [];


    /**
     * metodo que se ejecuta al inciar el modulo
     */
    mounted(): void {
        this.list();
    }


    /**
     * Procesa el formulario de creacion y edicion
     */
    handleSubmit(): void {

        if (this.company._id === null) {
            let url = `${BASE_URL_MANAGER}/company`;
            Axios.post(url, this.company)
                .then(res => {

                    this.$notification.success({
                        placement: "bottomRight",
                        message: "Compañia creada!",
                        description: `La compañia ${this.company.name}, se ha creado con exito!`
                    });
                    this.hide();
                    this.list();
                })
                .catch(err => {
                    this.$notification.error({
                        placement: "bottomRight",
                        message: "Ocurrio un error!",
                        description: `La compañia ${this.company.name}, no ha podido crearse!`
                    });
                    throw err;
                });
        } else {

            let url = `${BASE_URL_MANAGER}/company/${this.company._id}`;
            Axios.patch(url, this.company)
                .then(res => {
                    this.$notification.success({
                        placement: "bottomRight",
                        message: "Ccompaía actualizada!",
                        description: `La compañia ${this.company.name}, se ha modificado con exito!`
                    });
                    this.hide();
                    this.list();
                })
                .catch(err => {
                    this.$notification.error({
                        placement: "bottomRight",
                        message: "Ocurrio un error!",
                        description: `La compañia ${this.company.name} no se ha modificado!`
                    });
                    throw err;
                });
        }

    }

    /**
     * TODO: VALIDAR DORMULARIO
     */
    /*checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
    }*/

    /**
     * Muestra el formulario de nueva compañia
     */
    add(): void {
        this.$bvModal.show('companie-modal')
    }
    /**
     * abre el modal de crear admin
     */
    addAdmin() {

        $(`#companies-CreateAdmin`).modal('show');

    }
    /**
     * abre el modal de creacion de agente
     */
    addAgent() {
        $(`#companies-CreateAgent`).modal('show');
    }

    /**
     * muestra el modal de confirmacion de borrado
     */
    remove(item: any): void {
        this.company = item;
        this.$bvModal.show('confirm-modal');
    }
    /**
     * establece la companis
     */
    setCompany(item: any) {
        this.company = item;
        console.log(this.company);
    }

    /**
     * hide fom
     */
    hide(): void {
        this.$bvModal.hide('companie-modal')
    }
    /**
     * abro el modal para edicion
     */
    edit(id: string): void {

        let url = `${BASE_URL_MANAGER}/company/${id}`;
        Axios.get(url)
            .then((res: any) => {

                this.company._id = res.data._id;
                this.company.name = res.data.name;
                this.$bvModal.show('companie-modal')
            })
            .catch(err => {
                this.$notification.error({
                    placement: "bottomRight",
                    message: "Ocurrio un error!",
                    description: `error al traer la compañia!`
                });
                throw err;
            });

    }
    /**
     * Listo las companies
     */
    list(): void {
        let url = `${BASE_URL_MANAGER}/company`;
        Axios.get(url)
            .then(res => {
                this.companies = res.data.companies;
            })
            .catch(err => {
                throw err;
            });
    }

    /**
     * cuando cierro el modal limpio el formulario
     */
    resetModal(): void {
        this.company._id = null;
        this.company.name = '';
    }
    /**
     * 
     * @param bvModalEvt al presionar el boton de ok
     */
    handleOk(bvModalEvt: any): void {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
    }

    /**
     * procesa la eliiminacion
     */
    confirmDelete(bvModalEvt: any): void {
        bvModalEvt.preventDefault();

        let url = `${BASE_URL_MANAGER}/company/${this.company._id}`;
        Axios.delete(url)
            .then((err: any) => {
                this.$notification.success({
                    placement: "bottomRight",
                    message: "Compaía eliminada!",
                    description: `La compañia ${this.company.name}, se ha eliminado con exito!`
                });

                this.$bvModal.hide('confirm-modal');
                this.list();
            })
            .catch((err: any) => {
                this.$notification.error({
                    placement: "bottomRight",
                    message: "Ocurrio un error!",
                    description: `La compañia ${this.company.name} no se ha eliminado!`
                });
                throw err;
            });
    }

    /**
     * evento para el emiter del modal de crear admin
     */
    retrieveAdmins() {

        return;
    }


}